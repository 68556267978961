<template>
  <div class="edit-phone">
    <TopBar :backUrl="'/index'" :pageTitle="'绑定手机号'"/>
    <div>
      <div class="form-box type2">
        <div class="form-title">绑定手机号<span class="err-tip" v-if="codeErr!=''">{{codeErr}}</span></div>
        <div class="input-box type2">
          <input class="input" type="text" v-model="phone" placeholder="请输入手机号">
        </div>
        <div class="input-box type2 ver-code">
          <input class="input" type="text" placeholder="请输入验证码" v-model="verCode">
          <span class="code" @click="getCode()">{{getCodeBtnText}}</span>
        </div>
      </div>
      <button class="sub-btn" :class="{'disable': isDisable}" @click="subBtn">立即绑定</button>
    </div>
  </div>
</template>
<script>
import TopBar from '@/m/common/topBar.vue';
import { mapMutations} from "vuex";
export default {
  name: 'edit-phone',
  components: {
    TopBar
  },
  data(){
    return {
      getCodeBtnText: '获取验证码',
      verCode: '',
      phone: '',
      isDisable: true,
      codeErr: '',
      timer: ''
    }
  },
  mounted(){
    this.openId = this.$route.params.openid;
  },
  methods:{
    ...mapMutations(['ShowTipModal']), //tip弹窗
    getCode(){
      if(this.phone!='' && this.codeErr==''){
          this.sendSms(this.phone)
        }else if(this.phone==''){
          this.codeErr = '请先填写手机号'
        }
    },
    //请求发送验证码
    sendSms(phone){
      if(this.getCodeBtnText == '获取验证码'){
          this.$axios.post(`https://kskapi.kskstudy.com/common/sendSms`, {
          phone,
          sms_code_use: 'wxBindPhone'
        }).then(res => {
          this.ShowTipModal({
            text: res.msg,     //提示弹窗内容
          })
          if(res.code==0){
            let time = 60;
            
            this.getCodeBtnText = time + '秒后再获取'
            this.timer = setInterval(()=>{
              time --;
              if(time == 0){
                this.getCodeBtnText = '获取验证码';
                clearInterval(this.timer);
                this.timer = '';
              }else{
                this.getCodeBtnText = time+'秒后再获取';
              }
            }, 1000);
          }
        })
      }
    },
    //密码校验
    formValidation(){
      let nonNull = true;

      if(!IsRegular(this.phone)){
        this.codeErr = '请输入正确的手机号'
      }else{
        this.codeErr = ''
      }

      if(this.verCode == '' || this.phone == ''){
        nonNull = false;
      }

      if(this.codeErr=='' && nonNull){
        this.isDisable = false;
      }else{
        this.isDisable = true;
      }
    },
    subBtn(){
      let data = {
        phone: this.phone,
        code: this.verCode,
        open_id: this.openId
      }

      this.$axios.post(`/v1/px/login/bindPhone`, data).then(res => {
        if(res.code == 0){
          localStorage.setItem('auth', JSON.stringify(res.data.auth));
          localStorage.setItem('user', JSON.stringify(res.data.user));
          localStorage.setItem('userInfo', JSON.stringify(res.data.user));
          localStorage.setItem('logoDate', Date.parse(new Date())/1000);
          this.ShowTipModal({
            text: res.msg,     //提示弹窗内容
            ico: 'success',
            fun: ()=>{
              this.$router.push('/index');
            }
          })
        }else{
          this.ShowTipModal({
            text: res.msg,     //提示弹窗内容
          })
        }
      })
    }
  },
  watch:{
    phone: {
      handler(){
        //表单验证
        this.formValidation();
      }
    },
    verCode: {
      handler(){
        //表单验证
        this.formValidation();
      }
    }
  },
  beforeDestroy(){
    clearInterval(this.timer);
    this.timer = '';
  }
}
</script>

<style lang="scss" scoped>
//表单
.form-box {
  padding: 52px 36px 0;
  .form-title {
    font-size: 32px;
    font-weight: 500;
    white-space: nowrap;
  }
  &.type2 {
    .form {
      margin-bottom: 60px;
    }
    .form-title {
      font-size: 32px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .err-tip {
      font-size: 30px;
      color: #e15416;
    }
  }
}
.input-box {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .clear {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    // background: url(~@/assets/m/wrong-white.png) no-repeat center center rgba(126, 134, 158, 0.25);
    background-size: 22px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .eyes {
    width: 40px;
    height: 40px;
    // background: url(~@/assets/m/eyes1.png) left center no-repeat;
    background-size: 100%;
    &.open {
      // background: url(~@/assets/m/eyes2.png) left center no-repeat;
      background-size: 100%;
    }
  }
  &.type2 {
    width: 100%;
    padding: 0 30px;
    border: 1px solid #e5e5e5;
    margin-top: 30px;
    .input {
      box-sizing: border-box;
      width: 93%;
      padding: 26px 0 28px;
      font-size: 30px;
      &::placeholder {
        font-size: 30px;
        color: #999;
      }
    }
    &.ver-code {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .input {
        width: 400px;
      }
      .code {
        font-size: 30px;
        color: #0065df;
        white-space: nowrap;
      }
    }
  }
  &.disabled{
    background-color: rgba(59, 59, 59, 0.1);
    .input{
      background: none;
    }
  }
}
//大按钮
.sub-btn {
  display: block;
  width: 100%;
  height: 100px;
  background-color: #0065df;
  border-radius: 50px;
  font-size: 32px;
  color: #fff;
  margin: 0 auto;
  &.disable {
    background-color: #99c1f2;
  }
}
.sub-btn{
  width: 680px;
  margin: 80px auto 0;
}
.err-tip{
  width: 200px;
  font-size: 12px !important;
  text-align: right;
  white-space: normal;
}
</style>